<template>
	<div class="introduction-container">
		
		<div class="introduction-header-bg"  :style="{ 'background': pseudoElementBg}"><div class="text-title">{{$t('introduction.profile')}}</div></div>
		<!-- 轮播图 -->
		<div style="position: absolute;width: 100%;top: 64px;display: flex;justify-content: center;">
			<div class="video" v-if="videoUrl">
				<video
					id="video"
					class="video_box"
					:src="videoUrl"
					type-name="deCorporateVideo"
					:poster="videoPoster"
					width="100%"
					height="100%"
					webkit-playsinline="true"
					playsinline="true"
					preload="true"
					style="width= 100%; height=100%; object-fit: cover;border-radius: 10px;"
					x5-video-player-type="h5-page"
					x5-video-player-fullscreen="false"
					
					ref="video"
					@click="changePlay(1)"
				/>
				<div class="control-play">
					<div style="margin-right: 8px">{{ passTime }}</div>
					<div class="progress-bar"><van-slider v-model="value" :max="duration" :min="0" active-color="#fff" @input="onInput" @change="onChange" /></div>
					<div style="margin-left: 8px">{{ remainingTime }}</div>
				</div>
				<img src="@/assets/img/introduction/play.png" alt="" v-if="videoState" @click="changePlay" />
			</div>
			<div v-else class="video"> <Swipe type-name="deCompanyProfileFirst" /> </div>
		</div>
		<div class="show-btn-bg"><div class="show-btn">{{$t('introduction.promotional')}}</div></div>
		<!-- 图片展示 -->
		<div class="image-container"><img v-for="item in imgList" :key="item.uuid" :src="item.filePath" alt="" /></div>
		<!-- 水印 -->
		<WaterMark />
	</div>
</template>

<script>
import TopNavBar from './components/TopNavBar.vue';
import Swipe from './components/Swipe.vue';
import ImageList from './components/ImageList.vue';
// import videoPlay from './components/videoPlay.vue'
import { getFileList } from '@/api';
import { setBgColor } from '@/utils';
export default {
	name: 'Introduction',
	components: {
		TopNavBar,
		Swipe,
		ImageList
		// videoPlay
	},
	data() {
		return {
			videoUrl: '',
			videoPoster: '',
			videoState: true,
			playBtn: true, // 是否显示图片 图片和video互斥
			videoSource: '', // 视频资源
			isPlay: false, // 当前视频是否正在播放
			value: 0, // slider滑块的值
			currentTime: 0, // 当前播放时间
			duration: 100, // 总时长
			drag: false, // 用户是否在拖拽
			styleObj: {}, // 应用样式
			shrink: false,
			videos: null,
			imgList: '',
		};
	},
	computed: {
		// 总时长
		remainingTime() {
			const m = parseInt(this.duration / 60);
			const s = parseInt(this.duration % 60);
			return `${m < 10 ? '0' + m : m}:${s < 10 ? '0' + s : s}`;
		},
		// 已过时长
		passTime() {
			const m = parseInt(this.currentTime / 60);
			const s = parseInt(this.currentTime % 60);
			if (this.currentTime == this.duration) {
				this.videoState = true;
			}
			return `${m < 10 ? '0' + m : m}:${s < 10 ? '0' + s : s}`;
		},
		pseudoElementBg(){
			return setBgColor('companyProfileFirst1','companyProfileFirst2');
		}
	},
	created() {
		this.getFileList('deCorporateVideo');
		this.getFileList('deCorporateVideoPicture');
		this.getFileList('deCompanyProfile');
	},
	methods: {
		// 获取列表数据 deCorporateVideoPicture
		getFileList(i) {
			const params = {
				page: 0,
				pageSize: 0,
				typeName: i,
				parentUuid: this.$store.state.shopUuid
			};
			getFileList(params).then(res => {
				if (res.state == 100) {
					if (i == 'deCorporateVideo') {
						this.videoUrl = res.items[0]?.filePath;
						if (this.isLoad) {
							this.videoSource = this.sources;
						}
						if(this.videoUrl){
							this.bindEvent();
						}
						
					} else if (i == 'deCorporateVideoPicture') {
						this.videoPoster = res.items[0]?.filePath;
					} else if (i == 'deCompanyProfile') {
						this.imgList = res.items;
					}
				}
			});
		},
		changePlay(e) {
			this.videoState = !this.videoState;
			if (this.$refs.video.paused) {
				this.$refs.video.play();
				this.$refs.video.requestFullscreen()
			} else {
				this.$refs.video.pause();
				this.$refs.video.exitFullscreen()
			}
		},
		switchPlay() {
			this.playBtn = false;
			this.isPlay = !this.isPlay;
			if (this.isPlay) {
				this.shrink = true;
				this.$refs.video.play();
			} else {
				this.$refs.video.pause();
				this.shrink = false;
			}
		},
		resetVideo() {
			// //如果视频已经加载过 重置为初始状态 swipe滑动到下一页的时候调用可重置为视频尚未播放的初始状态
			if (this.isLoad) {
				this.playBtn = true;
				this.isPlay = false;
				this.video.currentTime = 0;
				this.video.pause();
				this.shrink = false;
			}
		},
		bindEvent() {
			var $this = this;
			this.$nextTick(() => {
				var videos = document.getElementById('video');
				document.getElementById('video').addEventListener('loadedmetadata', () => {
					$this.duration = videos.duration;
				});
				document.getElementById('video').addEventListener('timeupdate', () => {
					// 解决微信浏览器不能在canplay未正确读取时间长度的bug
					if ($this.duration == 0) {
						$this.duration = videos.duration;
					}
					$this.currentTime = videos.currentTime;
					if (!$this.drag) {
						$this.value = videos.currentTime;
					}
				});
				document.getElementById('video').addEventListener('ended', () => {
					$this.resetVideo();
				});
			});
		},
		onInput(value) {
			// 拖动的时候设置拖动状态为true
			this.drag = true;
		},
		onChange(value) {
			// 停止拖动的时候设置拖动状态为false
			this.videoState = false;
			this.$refs.video.play();
			this.drag = false;
			this.value = value;
			this.$nextTick(() => {
				var videos = document.getElementById('video');
				videos.currentTime = value;
			});
		}
	}
};
</script>

<style lang="less" scoped>
.introduction-container {
	padding-bottom: 100px;
	// padding-top: 60px;
	.van-swipe {
	    height: 184px;
	}
	.image-container {
		display: flex;
		flex-direction: column;

		img {
			width: 100%;
			height: auto;
		}
	}
	.show-btn-bg {
		height: 124px;
		width: 100%;
		background-color: #fefefe;
		display: flex;
		justify-content: center;
		align-items: center;
		.show-btn {
			width: 101px;
			height: 35px;
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;
			background: linear-gradient(90deg, #ab10fd 0%, #fa11fd 100%);
			box-shadow: 1px 2px 7px 0px rgba(0, 0, 0, 0.2);
			border-radius: 16px;
			font-size: 13px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #ffffff;
			margin-top: 36px;
		}
	}

	.introduction-header-bg {
		width: 100%;
		height: 212px;
		// background-color: #07C160;
		// border-radius: 0 0 100%/50% 15%;
		position: relative;
		.text-title {
			position: absolute;
			top: 20px;
			width: 100%;
			text-align: center;
			color: #ffffff;
			font-size: 15px;
			font-weight: 400;
		}
	}
	// .introduction-header-bg:before {
	// 	position: absolute;
	// 	left: 0;
	// 	bottom: -11px;
	// 	content: '';
	// 	z-index: 1;
	// 	height: 100px;
	// 	width: 50%;
	// 	border-radius: 0 0 50% 50%;
	// }
	.introduction-header-bg:after {
		position: absolute;
		right: 0;
		top: 0;
		bottom: -11px;
		content: '';
		z-index: 1;
		// height: 60px;
		// width: 50%;
		width: 100%;
		height: 212px;
		background-image: url(../../assets/img/introduction/1.png);
		background-size: 100% 100%;
	}
	// &::before {
	// 	content: '';
	// 	display: table;
	// }

	.top-nav-bar {
		position: fixed;
		top: 0;
		width: 100%;
		height: 60px;
		z-index: 999;
	}
	.video {
		width: 327px;
		height: 184px !important;
		position: relative;
		border-radius: 10px;
		z-index: 999;
		img {
			width: 53px;
			height: 53px;
			left: 50%;
			top: 50%;
			position: absolute;
			z-index: 999;
			transform: translate(-50%, -50%);
		}
	}
	//播放进度条
	.control-play {
		width: 100%;
		position: absolute;
		bottom: 0;
		padding: 0 12px;
		height: 60px;
		display: flex;
		align-items: center;
		color: #fff;
		.progress-bar {
			flex: 1;
			margin: 0 8px;
		}
	}
	/deep/.van-slider__button {
		width: 16.5px;
		height: 16.5px;
	}
	/deep/.van-slider {
		background: rgba(0, 0, 0, 0.5);
	}
}
</style>
